<template>
  <div class="home" ref="home">
    <!-- 轮播图 -->
    <div class="carousel-box">
      <Carousel :bannerList="bannerLists" />
      <!-- 登录注册 -->
      <div class="login-box">
        <div class="login-con" v-if="!isLogin">
          <div class="login-item">
            <div class="login-itemB">
              <img src="../assets/images/home/login1.png" alt="政府登录" />
              <p>政府登录</p>
              <div class="login-item-bak">
                <a-form layout="vertical" :model="formLoginZF">
                  <a-form-item>
                    <div class="wrapper-input">
                      <a-input
                        v-model:value="formLoginZF.user"
                        placeholder="请输入账号"
                        class="hoverable-input"
                      >
                        <template #prefix>
                          <UserOutlined style="color:rgba(0,0,0,.25)" />
                        </template>
                      </a-input>
                    </div>
                  </a-form-item>
                  <a-form-item>
                    <a-input
                      v-model:value="formLoginZF.password"
                      type="password"
                      placeholder="请输入密码"
                      class="hoverable-input"
                    >
                      <template #prefix>
                        <LockOutlined style="color:rgba(0,0,0,.25)" />
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <a-button
                      type="primary"
                      html-type="submit"
                      @click="onLogin('zhengfu','G')"
                      >确认登录</a-button
                    >
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
          <a-divider type="vertical" style="height:auto;" />
          <div class="login-item">
            <div class="login-itemB">
              <img src="../assets/images/home/login2.png" alt="金融机构登录" />
              <p>金融机构登录</p>
              <div class="login-item-bak">
                <a-form layout="vertical" :model="formLoginJG">
                  <a-form-item>
                    <a-input
                      v-model:value="formLoginJG.user"
                      placeholder="请输入账号"
                    >
                      <template #prefix>
                        <UserOutlined style="color:rgba(0,0,0,.25)" />
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <a-input
                      v-model:value="formLoginJG.password"
                      type="password"
                      placeholder="请输入密码"
                    >
                      <template #prefix>
                        <LockOutlined style="color:rgba(0,0,0,.25)" />
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <a-button
                      type="primary"
                      html-type="submit"
                      @click="onLogin('jigou','B')"
                      >确认登录</a-button
                    >
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
          <a-divider type="vertical" style="height:auto;" />
          <div class="login-item">
            <div class="login-itemB login-itemA">
              <img src="../assets/images/home/login3.png" alt="企业登录" />
              <p v-if="formLoginQY.type == 'E'">企业登录</p>
              <p v-if="formLoginQY.type == 'I'">个体登录</p>
              <div class="login-item-bak">
                <a-form layout="vertical" :model="formLoginQY">
                  <a-form-item>
                    <a-input
                      v-model:value="formLoginQY.user"
                      placeholder="请输入账号"
                    >
                      <template #prefix>
                        <UserOutlined style="color:rgba(0,0,0,.25)" />
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <a-input
                      v-model:value="formLoginQY.password"
                      type="password"
                      placeholder="请输入密码"
                    >
                      <template #prefix>
                        <LockOutlined style="color:rgba(0,0,0,.25)" />
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-radio-group
                  v-model:value="formLoginQY.type"
                >
                  <a-radio value="E">企业</a-radio>
                  <a-radio value="I">个体工商户</a-radio>
                </a-radio-group>
                  <a-form-item style="margin-top: 10px;">
                    <a-button
                      type="primary"
                      html-type="submit"
                      @click="onLogin('qiye',formLoginQY.type)"
                      >确认登录</a-button
                    >
                    <div class="company-box">
                      <span @click="$router.push({ path: '/Register' })"
                        >立即注册</span
                      >
                      <!-- <span @click="handleClickChangeType"
                        >个体工商</span
                      > -->
                    </div>
                    <div class="zfsq" @click="skipPage">
                      <!-- <p> 政府服务网登录</p> -->
                      <img src="../assets/images/home/zfsq.png" />
                      <p style="margin-left: 4px;">政府服务网登录</p>
                    </div>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </div>
        <div class="logincompany" v-if="isLogin">
          <div class="loginc-left">
            <div class="loginc-l-box" v-if="loginType === 'zhengfu'">
              <img src="../assets/images/zfLogin.jpg" />
              <span class="item-icons" style="background: #f15f5f">政</span>
            </div>
            <div
              class="loginc-l-box"
              v-if="loginType === 'qiye' || loginType === 'jigou' || loginType === 'geti'"
            >
              <a-avatar :src="companyAvatar"></a-avatar>
              <span class="item-icons" v-if="loginType === 'qiye'">企</span>
              <span class="item-icons" v-if="loginType === 'jigou'">金</span>
            </div>
            <div class="loginc-r-box">
              <p
                class="title"
                @click="$router.push({ path: '/PersonalLayout' })"
                v-if="orgName != '' && orgName != null && orgName != 'null'"
              >
                {{ orgName }}
              </p>
              <p
                class="title"
                @click="$router.push({ path: '/PersonalLayout' })"
                v-else
              >
                尚未认证
              </p>
              <div class="butt">
                <a-button
                  class="butta"
                  @click="$router.push('/PersonalLayout/Message')"
                >
                  <template #icon> <BellOutlined />系统通知 </template>
                  <a-badge :count="infoCount">
                    <a href="#" class="head-example" />
                  </a-badge>
                </a-button>
                <a-button class="buttb" @click="outShow = true">
                  <template #icon>
                    <RightSquareOutlined />
                  </template>
                  退出登录
                </a-button>
              </div>
            </div>
          </div>
          <a-divider type="vertical" style="height:100%;margin:0 15px" />
          <div class="loginc-right">
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'qiye' || loginType === 'jigou' || loginType === 'geti'"
            >
              <p class="itema">
                <img
                  src="../assets/images/jg1.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />需求项目
              </p>
              <p class="itemb">
                <span>{{ parseFloat(USER_COUNT.DEMAND_COUNT).toFixed(0) }}</span
                >个
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'qiye' || loginType === 'jigou' || loginType === 'geti'"
            >
              <p class="itema">
                <img
                  src="../assets/images/jg2.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />成功对接
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.CONTACT_SUCCESS_COUNT).toFixed(0)
                }}</span
                >个
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'qiye' || loginType === 'jigou' || loginType === 'geti'"
            >
              <p class="itema">
                <img
                  src="../assets/images/jg3.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />需求额度
              </p>
              <p class="itemb">
                <span>{{ parseFloat(USER_COUNT.DEMAND_SUM).toFixed(0) }}</span
                >万
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'qiye' || loginType === 'jigou' || loginType === 'geti'"
            >
              <p class="itema">
                <img
                  src="../assets/images/jg4.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />放款额度
              </p>
              <p class="itemb">
                <span>{{ parseFloat(USER_COUNT.CONTACT_SUM).toFixed(0) }}</span
                >万
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'zhengfu'"
            >
              <p class="itema">
                <img
                  src="../assets/images/zf1.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />企业数
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.ERTER_COUNT || 0).toFixed(0)
                }}</span
                >家
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'zhengfu'"
            >
              <p class="itema">
                <img
                  src="../assets/images/zf2.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />金融机构
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.BANK_COUNT || 0).toFixed(0)
                }}</span
                >家
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'zhengfu'"
            >
              <p class="itema">
                <img
                  src="../assets/images/zf3.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />产品数
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.PROD_COUNT || 0).toFixed(0)
                }}</span
                >款
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'zhengfu'"
            >
              <p class="itema">
                <img
                  src="../assets/images/zf4.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />融资需求
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.DEMAND_SUM || 0).toFixed(0)
                }}</span
                >万
              </p>
            </div>
            <div
              class="item"
              :style="loginitems"
              v-if="loginType === 'zhengfu'"
            >
              <p class="itema">
                <img
                  src="../assets/images/zf5.png"
                  style="width: 20px;height: 20px;margin-right: 10px;"
                />放款额度
              </p>
              <p class="itemb">
                <span>{{
                  parseFloat(USER_COUNT.CONTACT_SUM || 0).toFixed(0)
                }}</span
                >万
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务选择 -->
    <div class="service-box">
      <!-- 标题 -->
      <ModuleTitle title="服务选择" stitle="Service selection"></ModuleTitle>
      <!-- 轮播图 -->
      <div class="swiper-box">
        <swiper
          class="swiper-boxs"
          ref="mySwiper"
          :autoplay="{ delay: 4000 }"
          :loop="true"
          :slidesPerView="4"
          :spaceBetween="24"
        >
          <swiper-slide class="swi-slider">
            <div class="swi-item">
              <img
                src="../assets/images/home/swiper-box01.png"
                alt="我要贷款"
              />
              <div class="desc" @click="jumploan">
                <div class="descT">我要贷款</div>
                <div class="descS">精准对接</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swi-slider">
            <div
              class="swi-item"
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 7 } })
              "
            >
              <img
                src="../assets/images/home/swiper-box02.png"
                alt="我要担保"
              />
              <div class="desc">
                <div class="descT">我要担保</div>
                <div class="descS">担保无忧</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swi-slider">
            <div
              class="swi-item"
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 8 } })
              "
            >
              <img
                src="../assets/images/home/swiper-box03.png"
                alt="我要汇票"
              />
              <div class="desc">
                <div class="descT">我要找投资人</div>
                <div class="descS">投资无忧</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swi-slider">
            <div
              class="swi-item"
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 5 } })
              "
            >
              <img
                src="../assets/images/home/swiper-box04.png"
                alt="我要保险"
              />
              <div class="desc">
                <div class="descT">我要财税指导</div>
              </div>
            </div>
          </swiper-slide>
          <!--            <swiper-slide class="swi-slider"><div class="swi-item"><img src="../assets/images/home/swiper-box05.png" alt="我要转贷"><div class="desc">-->
          <!--              <div class="descT">我要转贷</div>-->
          <!--              <div class="descS">敬请期待</div>-->
          <!--            </div>   </div></swiper-slide>-->
          <!--            <swiper-slide class="swi-slider"><div class="swi-item"><img src="../assets/images/home/swiper-box06.png" alt="我要保理"><div class="desc">-->
          <!--              <div class="descT">我要保理</div>-->
          <!--              <div class="descS">敬请期待</div>-->
          <!--            </div>   </div></swiper-slide>-->
          <!--            <swiper-slide class="swi-slider"><div class="swi-item"><img src="../assets/images/home/swiper-box07.png" alt="我要融租"><div class="desc">-->
          <!--              <div class="descT">我要融租</div>-->
          <!--              <div class="descS">敬请期待</div>-->
          <!--            </div>   </div></swiper-slide>-->
          <!--            <swiper-slide class="swi-slider"><div class="swi-item"><img src="../assets/images/home/swiper-box08.png" alt="我要发债"> <div class="desc">-->
          <!--              <div class="descT">我要发债</div>-->
          <!--              <div class="descS">敬请期待</div>-->
          <!--            </div> </div></swiper-slide>-->
          <!--            <swiper-slide class="swi-slider"><div class="swi-item"><img src="../assets/images/home/swiper-box09.png" alt="赋能服务"><div class="desc">-->
          <!--              <div class="descT">赋能服务</div>-->
          <!--              <div class="descS">敬请期待</div>-->
          <!--            </div></div></swiper-slide>-->
          <!--            <div class="swiper-button-prev" @click="swiper.slideNext( )">-->
          <!--              <img src="../assets/images/footer/left.jpg" alt="">-->
          <!--            </div>-->
          <!--            <div class="swiper-button-next">-->
          <!--              <img src="../assets/images/footer/right.jpg" alt="">-->

          <!--            </div>-->
        </swiper>
      </div>
      <!-- 轮播图-数据排行-我要融资 -->
      <a-row class="service-con-box" :gutter="24">
        <!--          <a-col :span="8" class="service-swiper">-->
        <!--            <a-carousel :autoplay="true" arrows>-->
        <!--              <template #prevArrow>-->
        <!--                <div class="custom-slick-arrow" style="left: 15px;zIndex: 1">-->
        <!--                  <left-circle-outlined />-->
        <!--                </div>-->
        <!--              </template>-->
        <!--              <template #nextArrow>-->
        <!--                <div class="custom-slick-arrow" style="right: 15px;zIndex: 1">-->
        <!--                  <right-circle-outlined />-->
        <!--                </div>-->
        <!--              </template>-->
        <!--              <div class="service-swiper-item" v-for="(item,index) in bannerList">-->
        <!--                <img :src="$baseUrl+'/'+item.FILE_ADDR">-->
        <!--                <p>{{item.INFOR_TITLE}}</p>-->
        <!--              </div>-->
        <!--            </a-carousel>-->
        <!--          </a-col>-->
        <a-col style="padding-left: 0 !important;" :span="12">
          <div class="data-was-box" style="height: 496px;">
            <div class="datawas-tiitle">
              <div>
                <img
                  src="../assets/images/home/data-was01.png"
                  style="margin-right: 16px"
                />
                数据排行
              </div>
              <ul>
                <li
                  :class="{ datawasactive: item.id === datawasId }"
                  v-for="item in datawasTabs"
                  :key="item.id"
                  @click="onTab(item)"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <div class="datawas-con">
              <ul class="datawas-con-item" v-show="datawasId === 1">
                <div v-if="bList.length != 0">
                  <li v-for="(bItem, index) in bList" :key="index">
                    <a>
                      <span
                        class="sp-item"
                        :class="{
                          'sp-item-a': index == 0,
                          'sp-item-b': index == 1,
                          'sp-item-c': index == 2,
                        }"
                        >{{ index + 1 }}</span
                      >
                      {{ bItem.BANK_NAME }}
                    </a>
                    <!--                      <img src="../assets/images/home/arrow.png" alt="">-->
                  </li>
                </div>
                <div
                  v-else
                  style="text-align: center;heigth: 237px;line-height: 237px;"
                >
                  <img src="../assets/images/no-data.png" />
                </div>
              </ul>
              <ul class="datawas-con-item" v-show="datawasId === 2">
                <div v-if="eList.length != 0">
                  <li v-for="(eItem, ei) in eList" :key="ei">
                    <a>
                      <span
                        class="sp-item"
                        :class="{
                          'sp-item-a': ei == 0,
                          'sp-item-b': ei == 1,
                          'sp-item-c': ei == 2,
                        }"
                        >{{ ei + 1 }}</span
                      >
                      {{ eItem.BANK_NAME }}
                    </a>
                    <!--                      <img src="../assets/images/home/arrow.png" alt="">-->
                    <!--                      <ArrowRightOutlined class="sp-items" />-->
                  </li>
                </div>
                <div
                  v-else
                  style="text-align: center;heigth: 237px;line-height: 237px;"
                >
                  <img src="../assets/images/no-data.png" />
                </div>
              </ul>
              <ul class="datawas-con-item" v-show="datawasId === 3">
                <div v-if="rList.length != 0">
                  <li v-for="(rItem, ri) in rList" :key="ri">
                    <a>
                      <span
                        class="sp-item"
                        :class="{
                          'sp-item-a': ri == 0,
                          'sp-item-b': ri == 1,
                          'sp-item-c': ri == 2,
                        }"
                        >{{ ri + 1 }}</span
                      >
                      {{ rItem.BANK_NAME }}
                    </a>
                    <!--                      <img src="../assets/images/home/arrow.png" alt="">-->
                  </li>
                </div>
                <div
                  v-else
                  style="text-align: center;heigth: 237px;line-height: 237px;"
                >
                  <img src="../assets/images/no-data.png" />
                </div>
              </ul>
              <!--                <div class="datawas-con-more" v-if="bList.length != 0">-->
              <!--                  <a>查看更多...</a>-->
              <!--                </div>-->
            </div>
          </div>
        </a-col>
        <a-col style="padding-right: 0 !important;" :span="12">
          <div class="data-was-box" style="height: 496px;">
            <div class="datawas-tiitle">
              <div>
                <img
                  src="../assets/images/home/data-was02.png"
                  style="margin-right: 16px"
                />我要融资
              </div>
              <ul>
                <!--                  <li style="color: #fa7373;">HOT</li>-->
                <!--                  <li >信用</li>-->
                <!--                  <li  >抵押</li>-->
                <!--                  <li >科技</li>-->
                <!--                  <li >贷款</li>-->
                <!--                  <li >租赁</li>-->
              </ul>
            </div>
            <div class="financing-con">
              <a-form
                ref="ruleForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-item
                  label="融资金额："
                  name="amount"
                  style="margin-bottom: 27px"
                >
                  <a-select v-model:value="amount" placeholder="请选择融资金额">
                    <a-select-option
                      v-for="(quotaItem, qi) in quotaList"
                      :key="qi"
                      :value="quotaItem.BIANMA"
                      >{{ quotaItem.NAME }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
                <a-form-item
                  label="融资期限："
                  name="limit"
                  style="margin-bottom: 27px"
                >
                  <a-select v-model:value="limit" placeholder="请选择融资期限">
                    <a-select-option
                      v-for="(dateItem, di) in dateList"
                      :key="di"
                      :value="dateItem.BIANMA"
                      >{{ dateItem.NAME }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
                <a-form-item
                  label="担保方式："
                  name="guaranty"
                  style="margin-bottom: 27px"
                >
                  <a-select
                    v-model:value="guaranty"
                    placeholder="请选择担保方式"
                  >
                    <a-select-option
                      v-for="(methodItem, mi) in methodList"
                      :key="mi"
                      :value="methodItem.BIANMA"
                      >{{ methodItem.NAME }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
                <a-form-item
                  label="服务项目："
                  name="fuwuxiangmu"
                  style="margin-bottom: 27px"
                >
                  <a-select
                    v-model:value="fuwuxiangmu"
                    placeholder="请选择服务项目"
                  >
                    <a-select-option
                      v-for="(serviceItem, si) in serviceList"
                      :key="si"
                      :value="serviceItem.BIANMA"
                      >{{ serviceItem.NAME }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-form>
              <div class="financing-con-btn">
                <a-button
                  class="financing-con-btns"
                  @click="
                    $router.push({
                      path: '/FinancialProducts',
                      query: {
                        rzje: amount,
                        rzqx: limit,
                        dbfs: guaranty,
                        fwxm: fuwuxiangmu,
                      },
                    })
                  "
                  >搜索融资</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- 大数据统计 -->
    <div class="bigdata-box">
      <div class="bigdata-con">
        <div class="bigdata-con-top">
          <!-- 标题 -->
          <ModuleTitle title="成果展示" stitle="Data statistics"></ModuleTitle>
          <ul class="bigdata-total">
            <li :style="bigdataBacka">
              <p class="item-label">注册企业</p>
              <p class="item-content">
                {{
                  parseFloat(outLineDatas.registerEnterprise || 0).toFixed(0)
                }}
              </p>
              <!--              <p class="item-content">1885</p>-->
              <p class="item-unit">家</p>
            </li>
            <li :style="bigdataBackb">
              <p class="item-label">平台机构</p>
              <p class="item-content">
                {{ parseFloat(outLineDatas.BANK_COUNT || 0).toFixed(0) }}
              </p>
              <!--              <p class="item-content">35</p>-->
              <p class="item-unit">家</p>
            </li>
            <li :style="bigdataBackc">
              <p class="item-label">金融产品</p>
              <p class="item-content">
                {{ parseFloat(outLineDatas.PROD_COUNT || 0).toFixed(0) }}
              </p>
              <!--               <p class="item-content">68</p>-->
              <p class="item-unit">项</p>
            </li>
            <li :style="bigdataBackd">
              <p class="item-label">评审额度</p>
              <p class="item-content">
                {{ parseFloat(outLineDatas.DEMAND_SUM || 0).toFixed(0) }}
              </p>
              <!--              <p class="item-content">389400</p>-->
              <p class="item-unit">万元</p>
            </li>
            <!--            <li :style="bigdataBacke">-->
            <!--              <p class="item-label">融资金额</p>-->
            <!--              <p class="item-content">{{parseFloat(outLineDatas.CONTACT_SUM || 0).toFixed(0)}}</p>-->
            <!--              &lt;!&ndash;              <p class="item-content">115500</p>&ndash;&gt;-->
            <!--              <p class="item-unit">万元</p>-->
            <!--            </li>-->
          </ul>
        </div>
        <a-row class="bigdata-con-bottom">
          <a-col class="bot-item">
            <div class="bot-item-title">
              <span class="tit-sp">
                <img
                  src="../assets/images/home/rz.png"
                  alt
                  style="margin-right: 16px"
                />
                <!--                    <FolderOpenOutlined style="font-size:18px;" />-->
                融资需求
              </span>
              <a
                @click="
                  $router.push({ path: '/ResultsShow', query: { tab: 1 } })
                "
                >查看更多...</a
              >
            </div>

            <ul
              class="bot-item-con"
              style="height: 425px;overflow:hidden;"
              v-if="dList.length != 0"
            >
              <vue-seamless-scroll :data="dList" :class-option="classOption">
                <li class="slide-item" v-for="(dItem, di) in dList" :key="di">
                  <a>{{ dItem.ENTERPRISE_NAME }}</a>
                  <span>{{ dItem.SERVICE_ITEM }}</span>
                  <span class="point">{{ dItem.QUOTA_E }}万元</span>
                  <span>{{ dItem.ISSUED_DATE }}</span>
                </li>
              </vue-seamless-scroll>
            </ul>
            <ul
              class="bot-item-con"
              v-else
              style="height: 425px;overflow:hidden;line-height: 390px;text-align: center;"
            >
              <img src="../assets/images/no-data.png" />
            </ul>
          </a-col>
          <a-col class="bot-item">
            <div class="bot-item-title">
              <span class="tit-sp">
                <img
                  src="../assets/images/home/cg.png"
                  alt
                  style="margin-right: 16px"
                />
                获得融资
              </span>
              <a
                @click="
                  $router.push({ path: '/ResultsShow', query: { tab: 2 } })
                "
                >查看更多...</a
              >
            </div>

            <ul
              class="bot-item-con bot-item-cons1"
              style="height: 425px;;overflow:hidden;"
              v-if="sList.length != 0"
            >
              <vue-seamless-scroll :data="dList" :class-option="classOption">
                <li id="bot1" v-for="(sItem, si) in sList" :key="si">
                  <a>{{ sItem.ENTERPRISE_NAME }}</a>
                  <span class="point">{{ sItem.RECEIVE_MONEY }}万元</span>
                  <span>{{ sItem.R_DATE }}</span>
                </li>
              </vue-seamless-scroll>
            </ul>
            <ul
              class="bot-item-con bot-item-cons"
              v-else
              style="height: 425px;line-height: 425px;text-align: center;"
            >
              <img src="../assets/images/no-data.png" />
            </ul>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 热门产品 -->
    <div class="product-box">
      <div class="product-con">
        <!-- 标题 -->
        <ModuleTitle title="热门产品" stitle="Hot products"></ModuleTitle>
        <ul class="product-item" v-if="proList.length != 0">
          <li
            v-for="(proItem, proi) in proList"
            :key="proi"
            @click="
              $router.push({
                path: '/ProdutcDetails',
                query: { id: proItem.FINANCIALPRODUCTS_ID },
              })
            "
          >
            <div
              style="display: flex;align-items: flex-end;justify-content: center"
            >
              <div>
                <img
                  style="margin-right: 10px"
                  src="../assets/images/home/hot.png"
                />
              </div>
              <div>热门推送产品</div>
            </div>
            <!--            <img mode="center" style="width: 220px;height: 20px" :src="$baseUrl+'/'+proItem.LOGO_ADDR">-->
            <!--            <div>{{proItem.BANK_NAME}}</div>-->
            <a-divider style="margin-top:10px;margin-bottom:10px" />
            <p
              class="title"
              style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
            >
              {{ proItem.PRODUCT_NAME }}
            </p>
            <p
              class="rate"
              style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 218px"
            >
              {{ proItem.INTEREST_RATE_START }}
            </p>
            <div class="sp-box">
              <span
                >贷款期限：{{ proItem.DATE_START }} 至
                {{ proItem.DATE_END }}</span
              >
              <span>担保方式：{{ proItem.GUARANTEE_METHOD }}</span>
              <span v-if="proItem.num">累计申请：{{ proItem.num }}次</span>
              <span v-else>累计申请：{{ proItem.LINK_COUNT }}次</span>
            </div>
            <div class="but-box">
              <a-button>查看详情</a-button>
            </div>
          </li>
        </ul>
        <div v-else style="text-align: center;margin-top: 50px;">
          <img src="../assets/images/no-data.png" />
        </div>
      </div>
    </div>
    <!-- 政策资讯 -->
    <div class="news-box">
      <div class="news-con">
        <!-- 标题 -->
        <ModuleTitle title="政策资讯" stitle="Policy information"></ModuleTitle>
        <a-row :gutter="24" class="news-con-top">
          <a-col :span="12">
            <div class="news-item" style="height: 425px;overflow: hidden">
              <div class="newsi-title">
                <div class="title">
                  <img
                    src="../assets/images/home/zc.png"
                    style="margin-right: 16px"
                  />
                  <!--                    <AuditOutlined style="font-size:18px;color:#3c51c4" />-->
                  政策指引
                </div>
                <div class="conditions">
                  <span
                    :class="{ 'sp-active': pLevel === '' }"
                    @click="
                      pLevel = '';
                      getPolicyList();
                    "
                    >全</span
                  >
                  <a-divider class="sp-line" />
                  <span
                    :class="{ 'sp-active': pLevel === '国' }"
                    @click="
                      pLevel = '国';
                      getPolicyList();
                    "
                    >国</span
                  >
                  <a-divider class="sp-line" />
                  <span
                    :class="{ 'sp-active': pLevel === '省' }"
                    @click="
                      pLevel = '省';
                      getPolicyList();
                    "
                    >省</span
                  >
                  <a-divider class="sp-line" />
                  <span
                    :class="{ 'sp-active': pLevel === '市' }"
                    @click="
                      pLevel = '市';
                      getPolicyList();
                    "
                    >市</span
                  >
                  <a-divider class="sp-line" />
                  <span
                    :class="{ 'sp-active': pLevel === '区' }"
                    @click="
                      pLevel = '区';
                      getPolicyList();
                    "
                    >区</span
                  >
                </div>
                <a
                  class="more"
                  @click="
                    $router.push({
                      path: '/PolicyInformation',
                      query: { tab: 1 },
                    })
                  "
                  >查看更多...</a
                >
              </div>
              <a-spin tip="Loading..." :spinning="zczySpinning">
                <ul class="newsi-con" v-if="pList.length != 0">
                  <li
                    v-for="(pItem, pi) in pList"
                    :key="pi"
                    @click="
                      $router.push({
                        path: '/PolicyInformationDetails',
                        query: { id: pItem.INFORMATON_ID },
                      })
                    "
                  >
                    <a>
                      {{ pItem.INFOR_TITLE }}
                      <span class="sp-guo" v-if="pItem.POLICY_LEVEL === '国'"
                        >国</span
                      >
                      <span
                        class="sp-sheng"
                        v-else-if="pItem.POLICY_LEVEL === '省'"
                        >省</span
                      >
                      <span
                        class="sp-shi"
                        v-else-if="pItem.POLICY_LEVEL === '市'"
                        >市</span
                      >
                      <span
                        class="sp-qu"
                        v-else-if="pItem.POLICY_LEVEL === '区'"
                        >区</span
                      >
                      <!--                      <span class="sp-guo" v-if="pItem.POLICY_LEVEL === '国'">国</span>-->
                      <!--                      <span class="sp-guo" v-if="pItem.POLICY_LEVEL === '国'">国</span>-->
                    </a>
                    <span>{{ pItem.PROM_DATE }}</span>
                  </li>
                </ul>
                <ul
                  class="newsi-con"
                  v-else
                  style="height: 372px;line-height: 372px;text-align: center;border-top: 1px solid #eaeaea;"
                >
                  <img
                    src="../assets/images/no-data.png"
                    style="height: 68px;width: 150px;"
                  />
                </ul>
              </a-spin>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="news-item" style="height: 425px;overflow: hidden">
              <div class="newsi-title">
                <div class="title">
                  <img
                    src="../assets/images/home/zx.png"
                    style="margin-right: 16px"
                  />
                  资讯动态
                </div>
                <a
                  class="more"
                  @click="
                    $router.push({
                      path: '/PolicyInformation',
                      query: { tab: 2 },
                    })
                  "
                  >查看更多...</a
                >
              </div>
              <a-spin tip="Loading..." :spinning="zxdtSpinning">
                <ul class="newsi-con" v-if="nList.length != 0">
                  <li
                    v-for="(nItem, ni) in nList"
                    :key="ni"
                    @click="
                      $router.push({
                        path: '/PolicyInformationDetails',
                        query: { id: nItem.INFORMATON_ID },
                      })
                    "
                  >
                    <a>{{ nItem.INFOR_TITLE }}</a>
                    <span>{{ nItem.PROM_DATE }}</span>
                  </li>
                </ul>
                <ul
                  class="newsi-con"
                  v-else
                  style="height: 372px;line-height: 372px;text-align: center;border-top: 1px solid #eaeaea;"
                >
                  <img
                    src="../assets/images/no-data.png"
                    style="height: 68px;width: 150px;"
                  />
                </ul>
              </a-spin>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 智库辅导 -->
    <!--    <div class="coach-box">-->
    <!--      <div class="coach-con">-->
    <!--        <ModuleTitle title="赋能服务" stitle="Think tank coaching"></ModuleTitle>-->
    <!--        <ul class="coath-item">-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">商务代理</p>-->
    <!--            <div class="info">-->
    <!--              <span>已售：120</span>-->
    <!--              <span class="price">¥299</span>-->
    <!--            </div>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--              查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">信息服务</p>-->
    <!--            <div class="info">-->
    <!--              <span>已售：120</span>-->
    <!--              <span class="price">¥299</span>-->
    <!--            </div>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--              查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">战略策划</p>-->
    <!--            <div class="info">-->
    <!--              <span>已售：120</span>-->
    <!--              <span class="price">¥299</span>-->
    <!--            </div>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--              查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">文化创意</p>-->
    <!--            <div class="info">-->
    <!--              <span>已售：120</span>-->
    <!--              <span class="price">¥299</span>-->
    <!--            </div>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--              查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">财税咨询</p>-->
    <!--            <div class="info">-->
    <!--              <span>已售：120</span>-->
    <!--              <span class="price">¥299</span>-->
    <!--            </div>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--                查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <img src="../assets/images/coath-item01.jpg">-->
    <!--            <p class="title">法律顾问</p>-->
    <!--            <div class="but-box">-->
    <!--              <a-button>-->
    <!--                查看详情-->
    <!--              </a-button>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--        <a>查看更多</a>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="coach-box" style="cursor: pointer">
      <div class="coach-con">
        <ModuleTitle
          title="赋能服务"
          stitle="Think tank coaching"
        ></ModuleTitle>
        <ul class="coath-item">
          <li>
            <img
              src="../assets/images/mser01.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 1 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('0da04faf5fd75c2d25d354e6c9988143')">商务代理</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 1 } })
              "
              >商务代理</span
            >
          </li>
          <li>
            <img
              src="../assets/images/mser02.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 2 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('5e3cd54aad2eac566d3b088952d4cd23')">信息服务</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 2 } })
              "
              >信息服务</span
            >
          </li>
          <li>
            <img
              src="../assets/images/mser03.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 3 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('6a6df54ba96c1d19cd46e83e63c1c654')">战略策划</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 3 } })
              "
              >战略策划</span
            >
          </li>
          <li>
            <img
              src="../assets/images/mser04.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 4 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('b25298bea688317a37c5e1688b55a941')">文化创意</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 4 } })
              "
              >文化创意</span
            >
          </li>
          <li>
            <img
              src="../assets/images/mser05.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 5 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('c68e2941ae9f53c0471cdd168fb8ae39')">财税咨询</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 5 } })
              "
              >财税咨询</span
            >
          </li>
          <li>
            <img
              src="../assets/images/mser06.png"
              alt
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 6 } })
              "
            />
            <span></span>
            <!--            <a href="javascript:void(0)" onclick="specClick('ff1df01265a01d8c38d94e158d83544c')">法律顾问</a>-->
            <span
              @click="
                $router.push({ path: '/BusinessAngency', query: { id: 6 } })
              "
              >法律顾问</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partners-box" :style="partnersback">
      <div class="partners-con">
        <div class="partners-title-box" :style="partnerstitle">
          <p>合作伙伴</p>
          <span>Cooperative partner</span>
        </div>
        <ul class="partners-hover clearfix">
          <li>
            <div class="item-title clearfix">
              <p class="item-left">政府相关网站...</p>
              <p class="item-right">
                <a-divider type="vertical" />
                <DownCircleFilled style="color:#9fa8e1;font-size:18px;" />
              </p>
            </div>
            <div class="item-bak zf">
              <a target="_blank" href="https://www.hlj.gov.cn/"
                >黑龙江省人民政府</a
              >
              <a target="_blank" href="http://www.harbin.gov.cn/"
                >哈尔滨市人民政府</a
              >
              <a target="_blank" href="http://kfq.harbin.gov.cn/"
                >哈尔滨市经济技术开发区</a
              >
              <a target="_blank" href="http://www.hrbng.gov.cn/"
                >哈尔滨市南岗区人民政府</a
              >
              <a target="_blank" href="http://www.hrbdl.gov.cn/"
                >哈尔滨市道里区人民政府</a
              >
              <a target="_blank" href="http://www.hrbxf.gov.cn/"
                >哈尔滨市香坊区人民政府</a
              >
              <a target="_blank" href="http://www.hrbpf.gov.cn/"
                >哈尔滨市平房区人民政府</a
              >
              <a target="_blank" href="http://www.songbei.gov.cn/"
                >哈尔滨市松北区人民政府</a
              >
              <a target="_blank" href="http://www.hulan.gov.cn/"
                >哈尔滨市呼兰区人民政府</a
              >
              <a target="_blank" href="http://www.acheng.gov.cn/"
                >哈尔滨市阿城区人民政府</a
              >
              <a target="_blank" href="http://www.hrbsc.gov.cn/"
                >哈尔滨市双城区人民政府</a
              >
            </div>
          </li>
          <li>
            <div class="item-title clearfix">
              <p class="item-left">部委相关网站...</p>
              <p class="item-right">
                <a-divider type="vertical" />
                <DownCircleFilled style="color:#9fa8e1;font-size:18px;" />
              </p>
            </div>
            <div class="item-bak bw">
              <a target="_blank" href="http://czt.hlj.gov.cn/"
                >黑龙江省财政厅</a
              >
              <a target="_blank" href="http://dfjrjgj.hlj.gov.cn/"
                >黑龙江省地方金融监督管理局</a
              >
              <a target="_blank" href="http://sswt.hlj.gov.cn/"
                >黑龙江省商务厅</a
              >
              <a target="_blank" href="http://gxt.hlj.gov.cn/"
                >黑龙江省工信厅</a
              >
              <a target="_blank" href="http://drc.hlj.gov.cn/"
                >黑龙江省发展和改革委员会</a
              >
              <a target="_blank" href="http://tjj.hlj.gov.cn/"
                >黑龙江省统计局</a
              >
              <a target="_blank" href="http://amr.hlj.gov.cn/"
                >黑龙江省市场监督管理局</a
              >
              <a target="_blank" href="http://heilongjiang.chinatax.gov.cn/"
                >国家税务总局黑龙江省税务局</a
              >
            </div>
          </li>
          <li>
            <div class="item-title clearfix">
              <p class="item-left">金融机构网站...</p>
              <p class="item-right">
                <a-divider type="vertical" />
                <DownCircleFilled style="color:#9fa8e1;font-size:18px;" />
              </p>
            </div>
            <div class="item-bak jr">
              <a target="_blank" href="http://haerbin.pbc.gov.cn/"
                >中国人民银行哈尔滨中心支行</a
              >
              <a target="_blank" href="https://www.boc.cn/">中国银行</a>
              <a target="_blank" href="http://www1.ccb.com/hl/cn/index.html"
                >中国建设银行黑龙江省分行</a
              >
              <a target="_blank" href="http://www.abchina.com/cn/branch/hl/"
                >中国农业银行黑龙江省分行</a
              >
              <a target="_blank" href="http://www.icbc.com.cn/">中国工商银行</a>
              <a target="_blank" href="https://www.lj-bank.com/">龙江银行</a>
              <a target="_blank" href="https://www.hrbb.com.cn/">哈尔滨银行</a>
              <a target="_blank" href="https://www.spdb.com.cn/">浦发银行</a>
              <a target="_blank" href="http://www.cmbchina.com/">招商银行</a>
              <a target="_blank" href="https://www.cib.com.cn/cn/">兴业银行</a>
              <a target="_blank" href="http://www.cebbank.com/">光大银行</a>
              <a target="_blank" href="https://www.psbc.com/cn/grfw/"
                >邮政储蓄银行</a
              >
              <a target="_blank" href="http://www.bankcomm.com/">交通银行</a>
              <a target="_blank" href="http://www.citicbank.com/">中信银行</a>
              <a target="_blank" href="https://www.hxb.com.cn/">华夏银行</a>
              <a target="_blank" href="http://www.cgbchina.com.cn/">广发银行</a>
              <a target="_blank" href="http://bank.pingan.com/">平安银行</a>
              <a target="_blank" href="http://www.hrbrcb.com.cn/"
                >哈尔滨农商银行</a
              >
              <a target="_blank" href="http://www.boimc.com.cn/">内蒙古银行</a>
              <a target="_blank" href="http://www.bankofyk.com/">营口银行</a>
              <a target="_blank" href="http://www.hljrcc.com/"
                >黑龙江省农村信用社联合社</a
              >
              <a target="_blank" href="https://www.qxdb.org.cn/"
                >哈尔滨企业信用融资担保服务中心</a
              >
            </div>
          </li>
          <!--          <li>-->
          <!--            <div class="item-title clearfix">-->
          <!--              <p class="item-left">金融市场网站...</p><p class="item-right"><a-divider type="vertical" /><DownCircleFilled style="color:#9fa8e1;font-size:18px;" /></p>-->
          <!--            </div>-->
          <!--            <div class="item-bak">-->
          <!--              <a>政府一二三</a>-->
          <!--              <a>政府一二三</a>-->
          <!--              <a>政府一二三</a>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <div class="item-title clearfix">-->
          <!--              <p class="item-left">政策相关网站...</p><p class="item-right"><a-divider type="vertical" /><DownCircleFilled style="color:#9fa8e1;font-size:18px;" /></p>-->
          <!--            </div>-->
          <!--            <div class="item-bak">-->
          <!--              <a>政府一二三</a>-->
          <!--              <a>政府一二三</a>-->
          <!--              <a>政府一二三</a>-->
          <!--            </div>-->
          <!--          </li>-->
        </ul>
        <div class="partners-bot">
          <a>
            <img src="../assets/images/footer/1.png" />
          </a>
          <a>
            <img src="../assets/images/footer/2.png" />
          </a>
          <a>
            <img src="../assets/images/footer/3.png" />
          </a>
          <a>
            <img src="../assets/images/footer/4.png" />
          </a>
          <a>
            <img src="../assets/images/footer/5.png" />
          </a>
          <a>
            <img src="../assets/images/footer/6.png" />
          </a>
          <a>
            <img src="../assets/images/footer/7.png" />
          </a>
          <a>
            <img src="../assets/images/footer/8.png" />
          </a>
          <a>
            <img src="../assets/images/footer/9.png" />
          </a>
          <a>
            <img src="../assets/images/footer/10.png" />
          </a>
        </div>
      </div>
    </div>
    <a-modal
      :visible="outShow"
      title="信息提示"
      @cancel="outShow = false"
      @ok="exit"
      ok-text="确定"
      cancel-text="取消"
    >
      <p>您确定要退出登录吗？</p>
    </a-modal>

    <div class="sliderWrap" :style="{ top: top }">
      <div class="sl-item" @click="toTop(step)">置顶</div>
      <div
        class="sl-item"
        :style="{ background: showVideo ? '#376CE5' : '' }"
        @click="(showVideo = !showVideo), (showE = false)"
      >
        <img src="../assets/images/home/video.png" alt />
        <video
          controls
          style="position: absolute;right: 60px"
          v-show="showVideo"
          :src="'/video/san.mp4'"
        ></video>
      </div>
      <div
        class="sl-item"
        :style="{ background: showE ? '#376CE5' : '' }"
        @click="(showE = !showE), (showVideo = false)"
      >
        <img src="../assets/images/home/more.png" alt />
        <img
          v-show="showE"
          style="width: 280px;height:auto;position: absolute;right: 60px"
          src="../assets/images/home/ewm.png"
          alt
        />
      </div>
      <div class="sl-item" @click="toBottom(step)">置底</div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
import Carousel from "../components/Carousel";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Autoplay, Navigation]);
import {
  ArrowRightOutlined,
  FolderOpenOutlined,
  ShrinkOutlined,
  AuditOutlined,
  FileSearchOutlined,
  DownCircleFilled,
  UserOutlined,
  LockOutlined,
  BellOutlined,
  RightSquareOutlined,
} from "@ant-design/icons-vue";
import "swiper/swiper.less";
import "swiper/components/navigation/navigation.min.css";

// import companyAvatar from "@/assets/images/companyAvatar.jpg";
import companyAvatar from "@/assets/images/logob copy.png";
import { informationList } from "@/api/home/home";
import { mapGetters } from "vuex";
import { goLogoPic, logout } from "@/api/login/login";
import { uploadImg } from "@/api/other/other";

export default {
  components: {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    Autoplay,
    Navigation,
    ModuleTitle,
    Carousel,
    Swiper,
    SwiperSlide,
    ArrowRightOutlined,
    FolderOpenOutlined,
    ShrinkOutlined,
    AuditOutlined,
    FileSearchOutlined,
    DownCircleFilled,
    UserOutlined,
    LockOutlined,
    BellOutlined,
    RightSquareOutlined,
  },
  computed: {
    ...mapGetters(["isLogin", "userName", "loginType", "orgName", "orgId"]),
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      zczySpinning: false, //政策指引加载状态
      zxdtSpinning: false, //资讯动态加载状态
      showE: false,
      showVideo: false,
      top: "42%",
      bannerLists: [
        require("../assets/images/proBanner.jpg"),
        require("../assets/images/homeBan4.jpg"),
        require("../assets/images/homeBan3.jpg"),
        require("../assets/images/homeBan7.jpg"),
        require("../assets/images/homeBan5.png"),
        require("../assets/images/homeBan6.jpg"),
        require("../assets/images/homeBan8.png")
      ],
      bannerList: [],
      companyAvatar: companyAvatar,
      swiperbg: {
        backgroundImage:
          "url(" + require("../assets/images/swiper-back.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      bigdataBacka: {
        backgroundImage:
          "url(" + require("../assets/images/home/bigdata-top01.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      bigdataBackb: {
        backgroundImage:
          "url(" + require("../assets/images/home/bigdata-top02.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      bigdataBackc: {
        backgroundImage:
          "url(" + require("../assets/images/home/bigdata-top03.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      bigdataBackd: {
        backgroundImage:
          "url(" + require("../assets/images/home/bigdata-top04.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      bigdataBacke: {
        backgroundImage:
          "url(" + require("../assets/images/home/bigdata-top5.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      },
      partnersback: {
        backgroundImage:
          "url(" + require("../assets/images/partners01.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      partnerstitle: {
        backgroundImage:
          "url(" + require("../assets/images/partners-title.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },

      loginitems: {
        backgroundImage:
          "url(" + require("../assets/images/login-itemsa.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      datawasTabs: [
        { label: "放款最多", id: 1 },
        { label: "效率最快", id: 2 },
        { label: "利率最低", id: 3 },
      ],
      datawasId: 1,
      financingId: 1,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      amount: undefined,
      limit: undefined,
      guaranty: undefined,
      fuwuxiangmu: undefined,
      formLoginQY: {
        user: "",
        password: "",
        phone: "",
        type:"E"
      },
      formLoginJG: {
        user: "",
        password: "",
      },
      formLoginZF: {
        user: "",
        password: "",
      },
      dList: [],
      sList: [],
      pList: [],
      nList: [],
      proList: [],
      outLineDatas: {},
      bList: [],
      rList: [],
      USER_COUNT: "",
      serviceList: [],
      methodList: [],
      dateList: [],
      quotaList: [],
      eList: [],
      outShow: false,
      pLevel: "",
      infoCount: 0,
      step: 150,

    };
  },
  methods: {
    // // 个体工商点击事件
    // handleClickChangeType(){
    //   // this.ceshi1 = 'I'
    //   this.onLogin("qiye",'I')
    // },
    // 授权点击事件
    skipPage() {
      window.open(
        "https://sfrz.zwfw.hlj.gov.cn/f/apploginuser/appsysuser/applogin?ifSkip=1&uuid=3h6s9hrb53rsh92&toUrl=162f39871f3afc0cf5f99b8e5c8e22818b9d2a89e17f49bb3353068de15510ac58a6de5c3f2bbbd03f81c90c0731bf7f091d55333887744dec9a64fe8f39f44ad37c02a026fee541e6d521a66bc1af193023e3511a777fd15765aaffdfed998f40269807e1af56fc6098b9b4b4e53a8764c198dcdface96577b5acbff612968c66f7c1b381e11b47c3e6a17fa94bad461f8f919850d89a14cbe490752a00777de83c244afaa2abdcb598f03f3c3fa3da&isAes=1",
        "_blank"
      );
    },
    //li自动滚动
    scrollList() {
      console.log(document.getElementsByClassName("bot-item")[0]);
      // 获取当前列表ul
      const ulDom = document.getElementsByClassName("bot-item")[0];
      console.log("当前元素u", ulDom);
      // 获取当前lis
      const liDoms = document.getElementById("bot1");
      console.log("当前元素", liDoms);
      // 单个移动的最大值, 元素li的高度
      const maxL = liDoms[0].offsetHeight;
      console.log("当前元素", ulDom, liDoms, maxL);
      let startL = 0;
      // 移动方法
      const moveLi = () => {
        startL++;
        if (startL >= maxL) {
          startL = 0;
          let tempData = [...this.liData]; //深拷贝数据数组
          const tempLi = tempData.splice(0, 1); //当第一个li完全移出当前列表截取第一个数据
          tempData.push(...tempLi); //将被截取出去的li的数据放到数组最后一位
          this.liData = tempData;
        }
        ulDom.style.top = "-" + startL + "px"; //上移高度
      };
      setInterval(moveLi, 30);
    },
    //跳转到产品页面
    jumploan() {
      this.$router.push("/FinancialProducts");
    },
    //跳转到联系我们页面
    jumpLink() {
      this.$router.push("/ContactUs");
    },
    toTop(i) {
      //参数i表示间隔的幅度大小，以此来控制速度
      document.documentElement.scrollTop -= i;
      if (document.documentElement.scrollTop > 0) {
        var c = setTimeout(() => this.toTop(i), 16);
      } else {
        clearTimeout(c);
      }
    },
    toBottom(i) {
      var clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight;
      var height = scrollHeight - clientHeight; //超出窗口上界的值就是底部的scrolTop的值
      document.documentElement.scrollTop += i;
      if (document.documentElement.scrollTop < height) {
        var c = setTimeout(() => this.toBottom(i), 16);
      } else {
        clearTimeout(c);
      }
    },
    getBanner() {
      informationList({ tm: new Date().getTime() }).then((res) => {
        console.log(res);
        this.bannerList = res.varList;
      });
    },
    onLogin(lType,type) {
      let uName = "",
        uPwd = "",
        loType = "";
      if (lType === "qiye") {
        if (this.formLoginQY.user === "") {
          this.$message.info("请填写用户名");
          return false;
        } else if (this.formLoginQY.password === "") {
          this.$message.info("请填写密码");
          return false;
        }
        uName = this.formLoginQY.user;
        uPwd = this.formLoginQY.password;
        loType = type;
      } else if (lType === "jigou") {
        if (this.formLoginJG.user === "") {
          this.$message.info("请填写用户名");
          return false;
        } else if (this.formLoginJG.password === "") {
          this.$message.info("请填写密码");
          return false;
        }
        uName = this.formLoginJG.user;
        uPwd = this.formLoginJG.password;
        loType = type;
      } else if (lType === "zhengfu") {
        if (this.formLoginZF.user === "") {
          this.$message.info("请填写用户名");
          return false;
        } else if (this.formLoginZF.password === "") {
          this.$message.info("请填写密码");
          return false;
        }
        uName = this.formLoginZF.user;
        uPwd = this.formLoginZF.password;
        loType = type;
      }
      this.$store
        .dispatch("getLogin", {
          KEYDATA: "FSZN" + uName + ",ww," + uPwd,
          tm: new Date().getTime(),
          LOGIN_TYPE: loType,
        })
        .then((res) => {
          if (res.result === "success") {
            this.$store.dispatch("setLoginType", { lType: lType });
            this.$store
              .dispatch("getUserInfo", { USERNAME: this.userName })
              .then((res) => {
                console.log(res);
                // 获取是否上传4张认证图片
                uploadImg({ ENTERPRISE_ID: res.ORG_ID }).then((res) => {
                  console.log(res);
                  if (res.errInfo === "success") {
                    console.log("123");
                    this.$store.commit("SET_ISUPLOADIMG", res.flag);
                  }
                });
                this.getUserCount();
                //跳转到详情页面
                this.$router.push({ path: "/PersonalLayout" });
              });
          } else if (res.result === "usererror") {
            this.$message.info("用户名或密码有误");
          } else if (res.result === "error") {
            this.$message.info("缺少参数");
          } else if (res.result === "exception") {
            this.$message.info("系统异常");
          } else {
            this.$message.info("网络异常");
          }
        });
    },
    financingClick(item) {
      this.financingId = item.id;
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    onTab(item) {
      this.datawasId = item.id;
      if (item.id == 1) {
        this.getBankSum();
      } else if (item.id == 2) {
        this.getBankRunDaysASC();
      } else if (item.id == 3) {
        this.getRateASC();
      }
    },
    // 退出登录
    exit() {
      this.outShow = false;
      this.formLoginQY.user = "";
      this.formLoginQY.password = "";
      this.formLoginJG.user = "";
      this.formLoginJG.password = "";
      this.formLoginZF.user = "";
      this.formLoginZF.password = "";
      logout({
        tm: new Date().getTime(),
        USERNAME: this.userName,
      }).then((res) => {});
      this.$store.commit("loginOut");
    },
    // 获取融资需求列表
    getDemandList() {
      this.$store
        .dispatch("getDemandList", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            this.dList = res.varList;
          } else {
            this.dList = [];
          }
        });
    },
    // 获取成果对接列表
    getSuccessList() {
      this.$store
        .dispatch("getSuccessList", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            let datas = res.varList;
            if (datas.length != 0) {
              for (
                let i = 0, len = datas.length > 8 ? 9 : datas.length;
                i < len;
                i++
              ) {
                this.sList.push(datas[i]);
              }
            }
          } else {
            this.sList = [];
          }
        });
    },
    // 获取政策指引列表
    getPolicyList() {
      this.zczySpinning = true;
      this.$store
        .dispatch("getPolicyList", {
          showCount: 10,
          currentPage: 1,
          POLICY_LEVEL: this.pLevel,
          XCX_NAME: localStorage.getItem("xcxName"),
        })
        .then((res) => {
          if (res.result === "success") {
            this.zczySpinning = false;
            this.pList = res.varList;
          } else {
            this.pList = [];
          }
        });
    },
    // 获取资讯动态列表
    getNewsList() {
      this.zxdtSpinning = true;
      this.$store
        .dispatch("getNewsList", {
          showCount: "-1",
          currentPage: "1",
          XCX_NAME: localStorage.getItem("xcxName"),
        })
        .then((res) => {
          if (res.result === "success") {
            this.zxdtSpinning = false;
            this.nList = res.varList;
          } else {
            this.nList = [];
          }
        });
    },
    // 获取热门产品列表
    getProductList() {
      this.proList = [];
      this.$store
        .dispatch("getProductList", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            let datas = res.varList;
            if (datas.length != 0) {
              for (
                let i = 0,
                  len = res.varList.length > 4 ? 5 : res.varList.length;
                i < len;
                i++
              ) {
                this.proList.push(res.varList[i]);
              }
            } else {
              this.proList = [];
            }
          } else {
            this.proList = [];
          }
        });
    },
    // 获取大数据统计
    getOutLine() {
      let params = { XCX_NAME: localStorage.getItem("xcxName") };
      this.$store.dispatch("getOutLine", params).then((res) => {
        if (res.result === "success") {
          this.outLineDatas = res;
        } else {
          this.outLineDatas = {};
        }
      });
    },
    // 获取用户数据统计
    getUserCount() {
      let params = {};
      if (this.loginType === "qiye") {
        params = {
          ENTERPRISE_ID: this.orgId,
          XCX_NAME: localStorage.getItem("xcxName"),
        };
      } else if (this.loginType === "jigou") {
        params = {
          BANK_ID: this.orgId,
          XCX_NAME: localStorage.getItem("xcxName"),
        };
      } else if (this.loginType === "zhengfu") {
        params = {
          GOVERMENT_ID: this.orgId,
          XCX_NAME: localStorage.getItem("xcxName"),
        };
      }
      this.$store.dispatch("getUserOutLine", params).then((res) => {
        if (res.result === "success") {
          this.USER_COUNT = res;
          this.infoCount = res.MESSAGE_COUNT;
        } else {
          this.USER_COUNT = "";
        }
      });
    },
    // 获取放款最多列表
    getBankSum() {
      this.bList = [];
      this.$store
        .dispatch("getBankSum", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            for (let i in res.varList) {
              if (res.varList[i] != "" && res.varList[i] != null) {
                this.bList.push(res.varList[i]);
              }
            }
          } else {
            this.bList = [];
          }
        });
    },
    // 获取利率最低列表
    getRateASC() {
      this.$store
        .dispatch("getRateASC", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            this.rList = res.varList;
          } else {
            this.rList = [];
          }
        });
    },
    // 获取效率最快列表
    getBankRunDaysASC() {
      this.$store
        .dispatch("getBankRunDaysASC", { showCount: "-1", currentPage: "1" })
        .then((res) => {
          if (res.result === "success") {
            this.eList = res.varList;
          } else {
            this.eList = [];
          }
        });
    },
    getSelect() {
      // 获取服务内容下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "5e3766b8ee284c929c72cbfeba325b3b",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.serviceList = res.list;
          } else {
            this.serviceList = [];
          }
        });
      // 获取担保方式下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "6c14cf3422324b6aaf7a8637394c9b89",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.methodList = res.list;
          } else {
            this.methodList = [];
          }
        });
      // 获取期限范围下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "e7486ddcb4c143149deeecbe3ff9a58b",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.dateList = res.list;
          } else {
            this.dateList = [];
          }
        });
      // 获取额度范围下拉选项列表
      this.$store
        .dispatch("getServiceItemList", {
          DICTIONARIES_ID: "c81a6c18aef04a58892753d289640b9f",
          tm: new Date().getTime(),
        })
        .then((res) => {
          if (res.result === "success") {
            this.quotaList = res.list;
          } else {
            this.quotaList = [];
          }
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toTop);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  mounted() {
    // if (this.orgName) {
    this.getUserCount();
    // }
    // 获取放款最多列表
    this.getBankSum();
    this.getSelect();
    // 获取大数据统计
    this.getOutLine();
    // 获取融资需求列表
    this.getDemandList();
    // 获取成果对接列表
    this.getSuccessList();
    // 获取热门产品列表
    this.getProductList();
    // 获取政策指引列表
    this.getPolicyList();
    // 获取资讯动态列表
    this.getNewsList();
    this.getBanner();
    // this.scrollList()
  },
  created() {},
};
</script>

<style scoped lang="less">
.zfsq {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  width: 100%;
  // height: 60px;
  text-align: center;
  // line-height: 100px;
  color: #6ebae1;
  margin-top:4px;
  img {
    width: 25px;
    height: 25px;
  }
  // color: #fff;
  // width: 55% !important;
  // border-radius: 5px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
// ::v-deep .ant-input:focus{
//  z-index: 22;
// //  background-color: red;
// }
/* service-swiper 高度限制 */
.service-swiper .ant-carousel ::v-deep(.slick-slide) {
  overflow: hidden;
  height: 286px;
}
.service-swiper .ant-carousel ::v-deep(.slick-dots-bottom) {
  right: 10px;
  bottom: 16px;
}
.service-swiper .ant-carousel ::v-deep(.slick-dots) {
  width: auto;
}
/* 我要融资 ant样式修改 */
.financing-con ::v-deep(.ant-form-item) {
  margin-bottom: 5px;
}
.financing-con
  ::v-deep
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 53px;
  line-height: 53px;
}
.financing-con ::v-deep(.ant-form-item-label) {
  line-height: 53px;
}
.financing-con ::v-deep(.ant-select-selection-placeholder) {
  line-height: 53px !important;
}

::v-deep(.ant-badge) {
  margin-left: 15px;
  margin-bottom: 15px;
}

.financing-con ::v-deep(.ant-btn) {
  padding: 0 32px;
  color: #ffffff;
}

/* 登录表单样式修改 */
.login-item-bak ::v-deep(.ant-form-item) {
  margin-bottom: 15px;
}
.login-item-bak ::v-deep(.ant-btn) {
  width: 100%;
  padding: 0 32px;
  color: #ffffff;
  margin-bottom: 10px;
  background: #457dfe;
}
/* 热门产品 ant样式修改 */
.product-item .but-box ::v-deep(.ant-btn) {
  padding: 0 52px;
  color: #ffffff;
  border-radius: 60px;
  height: 48px;
  font-size: 18px;
  background: #457dfe;
}
/* 智库辅导样式 ant样式修改 */
.coath-item .but-box ::v-deep(.ant-btn) {
  padding: 0 52px;
  color: #ffffff;
  border-radius: 60px;
  height: 48px;
  font-size: 18px;
  background: -webkit-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* Firefox 3.6-15 */
  background: linear-gradient(
    to left,
    @color-blue,
    @color-purple
  ); /* 标准语法 */
}
</style>
<style lang="less" scoped>
.wrapper-input:hover .hoverable-input {
  pointer-events: none;
}
// 轮播图
.carousel-box {
  position: relative;
  .login-box {
    min-width: 1250px;
    height: 174px;
    padding: 20px;
    margin: 0px auto;
    z-index: 2;
    position: absolute;
    bottom: -140px;
    background-color: #ffffff;
    margin-left: -625px;
    left: 50%;
    box-shadow: 0px 2px 10px #929292;
    border-radius: 5px;
    .login-con {
      height: 152px;
      display: flex;
      justify-content: space-around;
      .login-item {
        width: 320px;
        text-align: center;
        font-size: @font-xl;
        color: @color-base;
        position: relative;
        // z-index: 9999;
        .login-itemB {
          position: absolute;
          padding: 10px;
          background-color: @color-ff;
          border-radius: 5px;
          cursor: pointer;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          p {
            display: block;
            margin-bottom: 0;
            height: 36px;
            line-height: 36px;
          }
          .company-box {
            display: flex;
            justify-content: space-between;
            font-size: @font-sm;
            color: @color-blue;
            span {
              width: 60px;
              display: block;
            }
          }
          .login-item-bak {
            padding-top: 20px;
            width: 80%;
            margin: 0 auto;
            padding: 0;
            height: 0;
            overflow: hidden;
            transition: all 0.2s linear;
            -moz-transition: all 0.2s linear; /* Firefox 4 */
            -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
            -o-transition: all 0.2s linear; /* Opera */
            .wrapper-input {
              position: relative;
            }
            .hoverable-input {
              pointer-events: auto;
              display: absolute;
            }
          }
        }
        .login-itemB:hover {
          .login-item-bak {
            padding-top: 20px;
            height: 230px;
          }
        }
        .login-itemA:hover {
          .login-item-bak {
            padding-top: 20px;
            height: 260px;
          }
        }
      }
    }
    .logincompany {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      height: 152px;
      .loginc-left {
        width: 430px;
        display: flex;
        justify-content: space-between;
        .loginc-l-box {
          position: relative;
          .ant-avatar {
            width: 100px;
            height: 100px;
            border: @border-base;
            border-radius: 100%;
          }
          .item-icons {
            font-size: 14px;
            color: #fff;
            background-color: #02c8c9;
            display: block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 100%;
            text-align: center;
            position: absolute;
            left: -14px;
            top: 20px;
          }
        }
        .loginc-r-box {
          width: 310px;
          .title {
            font-size: @font-xl;
            color: @color-base;
            cursor: pointer;
          }
          .butt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ant-btn {
              width: 120px;
              border-radius: 20px;
              color: #333;
            }
            .ant-btn:hover {
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
            }
            .butta {
              border-color: #3352c5;
              .anticon {
                color: #3352c5;
              }
            }
            .butta:hover {
              color: #3352c5;
            }
            .buttb {
              border-color: #f15f5f;
              .anticon {
                color: #f15f5f;
              }
            }
            .buttb:hover {
              color: #f15f5f;
            }
          }
        }
      }
      .loginc-right {
        /*width: 680px;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          width: 143px;
          height: 115px;
          padding: 15px;
          margin-right: 5px;
          p {
            margin-bottom: 0;
            color: #fff;
          }
          .itema {
            font-size: @font-md;
            display: flex;
            align-items: center;
            span {
              margin-right: 10px;
              font-size: @font-x20;
            }
          }
          .itemb {
            font-size: @font-md;
            text-align: right;
            height: 30px;
            margin-top: 28px;
            span {
              font-size: 22px;
              font-weight: bold;
              margin-right: 4px;
              text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
  .login-box::before {
    content: "";
    position: absolute;
    left: -4px;
    top: 50%;
    margin-top: -40px;
    width: 8px;
    height: 80px;
    background: #457dfe;
  }
  .login-box::after {
    content: "";
    position: absolute;
    right: -4px;
    top: 50%;
    margin-top: -40px;
    width: 8px;
    height: 80px;
    background: #457dfe;
  }
}
// 服务选择
.service-box {
  padding-top: 180px;
  padding-bottom: 50px;
  background-color: @color-fa;
  width: @main-width-base;
  margin: 0 auto !important;
  padding: 180px 0px 50px;
  .swiper-box {
    width: @main-width-base;
    height: 240px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin: 20px auto;
    padding: 0 32px;
    .swiper-boxs {
      margin: 0 auto;
      padding: 20px 0;
      .swi-slider {
        width: 326px !important;
      }
      .swi-item {
        position: relative;
        text-align: center;
        cursor: pointer;
        width: 326px;
        height: 200px;
        img {
          width: 326px;
          height: 200px;
          transition: all 0.1s linear;
          -moz-transition: all 0.1s linear; /* Firefox 4 */
          -webkit-transition: all 0.1s linear; /* Safari 和 Chrome */
          -o-transition: all 0.1s linear; /* Opera */
        }
        .desc {
          text-align: left;
          position: absolute;
          left: 32px;
          top: 64px;
          .descT {
            font-family: MicrosoftYaHei-Bold;
            font-size: 30px;
            color: #ffffff;
            text-shadow: rgba(0, 0, 0, 0.6) 0px 2px 5px;
          }
          .descS {
            font-family: MicrosoftYaHei-Bold;
            font-size: 20px;
            color: #ffffff;
            text-shadow: rgba(0, 0, 0, 0.6) 0px 2px 5px;
          }
        }
      }
      .swi-item:hover {
        img {
          transform: scale(1.05);
          -ms-transform: scale(1.05); /* IE 9 */
          -moz-transform: scale(1.05); /* Firefox */
          -webkit-transform: scale(1.05); /* Safari 和 Chrome */
          -o-transform: scale(1.05);
        }
      }
    }
  }
  .service-con-box {
    width: @main-width-base;
    margin: 0 auto !important;
    .service-swiper-item {
      width: 483px;
      height: 286px;
      position: relative;
      img {
        height: 100%;
      }
      p {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 36px;
        line-height: 36px;
        color: #ffffff;
        font-size: @font-lg;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.5);
        margin-bottom: 0;
        padding: 0 20px;
        padding-right: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .data-was-box {
      border: @border-base;
      border-radius: 5px;
      background-color: @color-ff;
      .datawas-tiitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px;
        border-bottom: @border-ea;
        div {
          font-size: @font-lg;
          color: @color-00;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          height: 71px;
          li {
            padding: 0 10px;
            font-size: @font-md;
            color: @color-bb;
            cursor: pointer;
            height: 71px;
            line-height: 71px;
          }
          .datawasactive {
            color: @color-base;
            border-bottom: 3px solid @color-blue;
          }
          .financingactive {
            color: @color-red;
          }
        }
      }
      .datawas-con {
        .datawas-con-item {
          height: 356px;
          overflow: hidden;
          padding: 0 10px;
          margin-bottom: 0;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: @border-ea;
            padding: 0 5px;
            a {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 70px;
              line-height: 70px;
              font-size: @font-md;
              color: @color-base;
              .sp-item {
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: @font-md;
                text-align: center;
                color: @color-ff;
                background-color: @color-bb;
                margin-right: 5px;
              }
              .sp-item-a {
                background-color: @color-guo;
              }
              .sp-item-b {
                background-color: @color-sheng;
              }
              .sp-item-c {
                background-color: @color-qu;
              }
            }
            .sp-items {
              color: @color-blue;
            }
          }
        }
        .datawas-con-more {
          display: flex;
          justify-content: flex-end;
          a {
            height: 70px;
            line-height: 70px;
            padding-right: 10px;
            font-size: @font-md;
            color: @color-blue;
          }
        }
      }
      .financing-con {
        padding: 10px;
        .financing-con-btn {
          padding-top: 3px;
          display: flex;
          justify-content: center;
          .financing-con-btns {
            width: 330px;
            height: 47px;
            background-color: #457dfe;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
// 大数据统计
.bigdata-box {
  padding: 50px 0;
  background-color: @color-ff;
  .bigdata-con {
    width: @main-width-base;
    margin: 0 auto !important;
    .bigdata-con-top {
      .bigdata-total {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 0;
        li {
          width: 252px;
          height: 252px;
          text-align: center;
          p {
            margin-bottom: 0px;
            color: @color-ff;
          }
          .item-label {
            font-size: @font-lg;
            margin-top: 140px;
          }
          .item-content {
            font-size: @font-xll;
            font-weight: bold;
            height: 48px;
            line-height: 48px;
            text-shadow: 2px 2px 8px @color-base;
          }
          .item-unit {
            font-size: @font-lg;
          }
        }
      }
    }
    .bigdata-con-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      .bot-item {
        width: 708px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        .bot-item-title {
          border-bottom: 1px solid #eaeaea;
          padding: 0 10px;
          height: 46px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: @color-00;
          font-weight: bold;
          border-radius: 5px 5px 0 0;
          a {
            color: #457dfe;
          }
          .tit-sp {
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
        .bot-item-con {
          padding: 10px 15px;

          li {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            border-bottom: @border-ea;
            a,
            span {
              color: @color-base;
              font-size: @font-md;
            }
            a {
              display: inline-block;
              width: 260px;
            }
            .point {
              font-weight: bold;
              color: @color-blue;
            }
          }
        }
        .bot-item-cons {
          li {
            a {
              display: inline-block;
              width: 360px;
            }
          }
        }
      }
    }
  }
}
// 热门产品
.product-box {
  padding: 50px 0;
  background-color: @color-fa;
  .product-con {
    width: @main-width-base;
    margin: 0 auto;
    padding: 0 50px;
    .product-item {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        background-color: @color-ff;
        padding: 10px;
        border: @border-base;
        width: 240px;
        height: 320px;
        border-radius: 5px;
        box-shadow: 0px 0px 8px #929292;
        text-align: center;
        p {
          margin-bottom: 0;
        }
        .title {
          font-weight: bold;
          color: @color-base;
          height: 80px;
          line-height: 80px;
          font-size: @font-x30;
          margin-top: 20px;
        }
        .rate {
          font-weight: bolder;
          font-size: @font-x20;
          color: @color-blue;
          height: 40px;
          line-height: 40px;
          margin-bottom: 10px;
        }
        .sp-box {
          text-align: left;
          span {
            display: block;
            color: @color-base;
            font-size: @font-md;
            margin-bottom: 2px;
          }
        }
      }
      li::before {
        content: "";
        position: absolute;
        left: -4px;
        top: 63px;
        width: 8px;
        height: 80px;
        background: #457dfe;
      }
      li::after {
        content: "";
        position: absolute;
        right: -4px;
        top: 63px;
        width: 8px;
        height: 80px;
        background: #457dfe;
      }
      .but-box {
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: -89px;
      }
    }
  }
}
// 政策资讯
.news-box {
  padding: 50px 0;
  background-color: @color-ff;
  .news-con {
    width: @main-width-base;
    margin: 0 auto;
    .news-con-top {
      margin-top: 50px;
      .news-item {
        padding: 10px;
        border: @border-base;
        border-radius: 5px;
        .newsi-title {
          display: flex;
          justify-content: space-between;
          height: 40px;
          align-items: center;
          .title {
            font-size: @font-lg;
            color: @color-00;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .conditions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              display: inline-block;
              position: relative;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background-color: #a3a3a3;
              text-align: center;
              line-height: 24px;
              color: @color-ff;
              font-size: @font-md;
              border: 4px solid #cccccc;
              cursor: pointer;
            }
            span:hover {
              border: 4px solid #e0dbf2;
              background-color: @color-blue;
            }
            .sp-active {
              border: 4px solid #e0dbf2;
              background-color: @color-blue;
            }
            .sp-line {
              width: 20px;
              margin: 0;
              min-width: 20px;
              background: #e2e2e2;
            }
          }
          .more {
            color: @color-blue;
          }
        }
        .newsi-con {
          margin-bottom: 0;
          margin-top: 10px;
          li {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            border-top: @border-ea;
            a {
              width: 586px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: @font-md;
              color: @color-base;
              span {
                padding: 2px;
                color: @color-ff;
                border-radius: 2px;
                margin-left: 10px;
              }
              .sp-guo {
                background-color: @color-guo;
              }
              .sp-sheng {
                background-color: @color-sheng;
              }
              .sp-shi {
                background-color: @color-shi;
              }
              .sp-qu {
                background-color: @color-qu;
              }
            }
          }
        }
      }
    }
  }
}
// 智库辅导
.coach-box {
  padding: 50px 0;
  background-color: @color-fa;
  .coach-con {
    width: @main-width-base;
    margin: 0 auto;
    padding: 0 50px;
    .coath-item {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      //&:before {
      //  content: "";
      //  display: block;
      //  width: 1200px;
      //  border-top: 2px solid #dfa264;
      //  position: absolute;
      //  top: 86px;
      //  left: 40px;
      //}
      li {
        width: 200px;
        height: 200px;
        padding: 10px;
        //background-color: @color-ff;
        border-radius: 5px;
        //box-shadow: 0px 0px 8px #929292;
        text-align: center;
        .title {
          font-size: @font-lg;
          color: @color-base;
          font-weight: bold;
          text-align: left;
          margin-top: 10px;
          line-height: 28px;
          margin-bottom: 10px;
        }
        .info {
          display: flex;
          justify-content: space-between;
          height: 36px;
          line-height: 36px;
          span {
            color: @color-99;
            font-size: @font-md;
          }
          .price {
            font-weight: bold;
            font-size: @font-x20;
            color: @color-blue;
          }
        }
        .but-box {
          padding-top: 20px;
          border-top: @border-ea;
        }
      }
      img {
        width: 100%;
      }
      //span{
      //  &:before{
      //    content: "";
      //    display: block;
      //    width: 20px;
      //    height: 20px;
      //    background-color: #dfa264;
      //    border-radius: 100%;
      //    position: absolute;
      //    left: 370px;
      //    top: 4150px;
      //  }
      //
      //  &:after{
      //    content: "";
      //    display: block;
      //    height: 55px;
      //    border-left: 4px dashed #dfa264;
      //    position: absolute;
      //    left: 380px;
      //    top: 4105px
      //  }
      //}
    }
  }
}
// 合作伙伴
.partners-box {
  height: 600px;
  .partners-con {
    width: @main-width-base;
    margin: 0 auto;
    padding: 50px;
    position: relative;
    .partners-title-box {
      margin: 0 auto;
      width: 550px;
      height: 56px;
      text-align: center;
      p {
        font-size: 24px;
        width: 150px;
        margin: 0 auto;
        color: @color-ff;
        height: 32px;
        line-height: 32px;
        letter-spacing: 2px;
      }
      span {
        color: #999999;
        display: block;
        width: 150px;
        margin: 0 auto;
        font-size: @font-sm;
        letter-spacing: 1px;
      }
    }
    .partners-hover {
      position: absolute;
      z-index: 99;
      left: 22%;
      margin-top: 50px;
      li {
        width: 240px;
        float: left;
        margin-left: 32px;
        background-color: @color-ff;
        border-radius: 5px;
        padding: 0px 10px;
        box-shadow: 0px 0px 5px @color-base;
        .item-title {
          padding: 5px 10px;
          p {
            margin: 0;
            font-size: @font-md;
            color: @color-bb;
            line-height: 30px;
          }
          .item-left {
            float: left;
          }
          .item-right {
            float: right;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
          }
        }
        .item-bak {
          height: 0;
          overflow: hidden;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear; /* Firefox 4 */
          -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
          -o-transition: all 0.2s linear; /* Opera */
          a {
            display: block;
            border-top: @border-ea;
            height: 36px;
            padding: 5px 10px;
            color: @color-99;
          }
          a:hover {
            color: @color-blue;
            text-decoration: underline;
          }
        }
      }
      li:first-child {
        margin-left: 0;
      }
      li:hover {
        .zf {
          height: 36 * 11px;
        }
        .bw {
          height: 36 * 8px;
        }
        .jr {
          height: 36 * 12px;
          overflow-y: scroll;
        }
        .item-title {
          p {
            color: @color-blue;
          }
        }
      }
    }
    .partners-bot {
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        display: block;
        width: 227px;
        height: 137px;
        margin-top: 39px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
::v-deep(.swiper-button-next:after) {
  transform: rotate(-90deg);
  border-left: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid @color-blue;
  border-radius: 10px;
  content: "";
  position: relative;
  z-index: 100;
  right: -20px;
  width: 0;
}
::v-deep(.swiper-button-prev:after) {
  transform: rotate(90deg);
  border-left: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid @color-blue;
  border-radius: 10px;
  content: "";
  left: -20px;
  position: relative;
  z-index: 100;
  width: 0;
}
.sliderWrap {
  z-index: 10;
  position: fixed;
  display: flex;
  right: 10px;
  top: 45%;
  flex-direction: column;
  align-items: center;
  .sl-item {
    cursor: pointer;
    margin-top: 2px;
    width: 60px;
    height: 60px;
    background: #e4e4e4;
    text-align: center;
    line-height: 60px;
    color: #ffffff;
    font-size: 14px;

    &:hover {
      background: #376ce5;
    }
  }
}
</style>
